import { IIconProps } from '../interface';

const Svgbell = ({
  width = 14,
  height = 12,
  color = '#777A83',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#828299"
    >
      <path
        d="M21.6667 10.8C21.6667 8.99653 20.9643 7.26692 19.714 5.99167C18.4638 4.71643 16.7681 4 15 4C13.2319 4 11.5362 4.71643 10.286 5.99167C9.03571 7.26692 8.33333 8.99653 8.33333 10.8C8.33333 18.7333 5 21 5 21H25C25 21 21.6667 18.7333 21.6667 10.8Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M17.7295 24C17.4521 24.6072 17.0539 25.1113 16.5748 25.4617C16.0956 25.812 15.5524 25.9965 14.9995 25.9965C14.4466 25.9965 13.9034 25.812 13.4243 25.4617C12.9452 25.1113 12.547 24.6072 12.2695 24"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
};

export default Svgbell;
