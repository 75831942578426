import React, { useState } from 'react';
import { localize } from '@cometchat/chat-uikit-react';
import './CometchatTabs.css';

export const CometChatTabs = (props: {
  onTabClicked?: (tabItem: { name: string; icon?: string }) => void;
  activeTab?: string;
}) => {
  const { onTabClicked = () => {}, activeTab } = props;

  const [hoverTab, setHoverTab] = useState('');

  const tabItems = [
    {
      name: localize('CHATS'),
      icon: 'https://cdn-icons-png.flaticon.com/512/2462/2462719.png', // Chat icon
    },
    {
      name: localize('CALLS'),
      icon: 'https://cdn-icons-png.flaticon.com/512/3209/3209265.png', // Calls icon
    },
    {
      name: localize('USERS'),
      icon: 'https://cdn-icons-png.flaticon.com/512/847/847969.png', // Users icon
    },
    {
      name: localize('GROUPS'),
      icon: 'https://cdn-icons-png.flaticon.com/512/561/561127.png', // Groups icon
    },
  ];

  return (
    <div className="cometchat-tab-component">
      {tabItems.map((tabItem) => (
        <div
          key={tabItem.name}
          className="cometchat-tab-component__tab"
          onClick={() => onTabClicked(tabItem)}
        >
          <div
            className={
              activeTab === tabItem.name.toLowerCase() ||
              hoverTab === tabItem.name.toLowerCase()
                ? 'cometchat-tab-component__tab-text cometchat-tab-component__tab-text-active'
                : 'cometchat-tab-component__tab-text'
            }
            onMouseEnter={() => setHoverTab(tabItem.name.toLowerCase())}
            onMouseLeave={() => setHoverTab('')}
          >
            {tabItem.name}
          </div>
        </div>
      ))}
    </div>
  );
};
