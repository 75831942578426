import { useEffect, useState } from 'react';
import { supabase } from '../../../supabaseClient';
import CometChat from '../../../newCometChat';
import env from '../../../env';
import { useNavigate } from 'react-router-dom';
import { addBreadcrumb } from '@sentry/react';
import { AppsCategoryEnum, Level } from '../../../utils/sentry';
import { CometChatApp } from '../../../components/Cometchat';

const NewSupport = () => {
  const [ccUser, setUser] = useState<CometChat.User>();
  const navigate = useNavigate();

  const handleInit = async () => {
    const { data: sessionData } = await supabase.auth.getSession();
    const user = sessionData?.session?.user;
    const userId = user?.id;
    const userEmail = user?.email;

    if (userEmail !== env.APP_TYTLE_EMAIL) {
      navigate('/advice');
      return;
    }

    try {
      const loggedinUser = await CometChat.getLoggedinUser();
      if (!loggedinUser) {
        const user = await CometChat.login(userId || '');
        setUser(user);
      } else {
        setUser(loggedinUser);
      }
    } catch (error) {
      addBreadcrumb({
        category: AppsCategoryEnum.APP,
        message: 'Error while cheking user authorization',
        level: Level.Error,
        data: { error },
      });
    }
  };

  useEffect(() => {
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ccUser) {
    return null;
  }
  return (
    <div className="h-screen">
      <CometChatApp/>
    </div>
  );
};

export default NewSupport;
