import { CometChatUIKit, UIKitSettingsBuilder } from "@cometchat/chat-uikit-react";
import env from "./env";

const COMETCHAT_CONSTANTS = {
  APP_ID: env.APP_COMETCHAT_ID || '',
  REGION: env.APP_COMETCHAT_REGION || '',
  AUTH_KEY: env.APP_COMETCHAT_AUTH_KEY || '',
};

//create the builder
const UIKitSettings = new UIKitSettingsBuilder()
  .setAppId(COMETCHAT_CONSTANTS.APP_ID)
  .setRegion(COMETCHAT_CONSTANTS.REGION)
  .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
  .subscribePresenceForAllUsers()
  .build();

//Initialize CometChat UI Kit
CometChatUIKit.init(UIKitSettings)!
  .then(() => {
    console.log("NEW - Initialization completed successfully");
    // You can now call login function.
  })
  .catch(console.log);

export default CometChatUIKit